const filterRegionSpecificPrices = {
  US: {
    air: {
      base: 349,
    },
    airMini: {
      base: 199,
    },
    milaFilter: {
      base: 59,
    },
    milaSocks: {
      base: 50,
    },
  },
  CA: {
    air: {
      base: 470,
    },
    airMini: {
      base: 270,
    },
    milaFilter: {
      base: 80,
    },
    milaSocks: {
      base: 65,
    },
  },
};

export default filterRegionSpecificPrices;
